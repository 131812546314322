import { SurveyAnswerValueType } from '@/types';
import { create } from 'zustand';

type SurveySubmissionStoreState = {
	answers: Record<string, SurveyAnswerValueType>;
	errors: Record<string, string>;
	contactInfo: ContactInfo;
};

type SurveySubmissionStoreActions = {
	setAnswer: (questionID: string, value: SurveyAnswerValueType) => void;
	setErrors: (errors: Record<string, string>) => void;
	setContactInfo: (contactInfo: ContactInfo) => void;
	reset: () => void;
};

const initialState: SurveySubmissionStoreState = {
	answers: {},
	errors: {},
	contactInfo: {},
};

type SurveySubmissionStore = SurveySubmissionStoreState & SurveySubmissionStoreActions;

export const useSurveySubmissionStore = create<SurveySubmissionStore>((set, get) => ({
	...initialState,
	setAnswer: (questionID, value) => set({ answers: { ...get().answers, [questionID]: value } }),
	setContactInfo: (contactInfo) => set({ contactInfo }),
	setErrors: (errors) => set({ errors }),
	reset: () => set(initialState),
}));

export type ContactInfo = {
	contactID?: string;
	email?: string;
	phone?: string;
};
