import React from 'react';
import { create } from 'zustand';

interface MaximizeStore {
	isMaximized: boolean;
	exitConfirmed?: boolean;
	setExitConfirmed: (exitConfirmed: boolean) => void;
	content: (() => React.ReactNode) | null;
	maximizedKey: string | undefined;
	maximize: (content: () => React.ReactNode, config?: MaximizeConfig & { maximizedKey?: string }) => void;
	unmaximize: (ignoreExitConfirm?: boolean) => void;

	config: MaximizeConfig | null;
	setConfig: (config: MaximizeConfig) => void;

	unmaximizeCallbacks: Array<() => void>;
	addUnmaximizeCallback: (callback: () => void) => void;
	removeUnmaximizeCallback: (callback: () => void) => void;

	trackedParams: Set<string>; // Track query parameters to remove
	trackParam: (param: string) => void;
	removeTrackedParam: (param: string) => void;
}

type MaximizeConfig = {
	centerTitle?: string;
	extra?: () => React.ReactNode;
	confirmExit?: boolean;
};

export const useMaximizeStore = create<MaximizeStore>((set, get) => ({
	isMaximized: false,
	content: null,
	maximizedKey: location.search.split('maximized=')[1]?.split('&')[0],
	config: null,
	unmaximizeCallbacks: [],
	trackedParams: new Set(),

	setExitConfirmed: (exitConfirmed) => {
		set({ exitConfirmed });
	},

	setConfig: (config) => {
		set({ config });
	},

	maximize: (content, config?: MaximizeConfig & { maximizedKey?: string }) => {
		if (config?.maximizedKey) {
			const url = new URL(window.location.href);
			// Save the current state before changing the URL
			window.history.pushState({ prevUrl: window.location.href }, '', window.location.href);
			url.searchParams.set('maximized', config.maximizedKey);
			window.history.pushState({}, '', url.toString());
		}

		set({
			isMaximized: true,
			content,
			maximizedKey: config?.maximizedKey,
			config,
			exitConfirmed: false,
		});
	},

	unmaximize: (ignoreExitConfirm?: boolean) => {
		const { config, exitConfirmed } = get();
		if (config?.confirmExit && !exitConfirmed && !ignoreExitConfirm) {
			return set({ exitConfirmed: true });
		}

		const url = new URL(window.location.href);

		// Remove 'maximized' param if present
		if (url.searchParams.has('maximized')) {
			url.searchParams.delete('maximized');
		}

		// Remove all tracked params
		get().trackedParams.forEach((param) => {
			if (url.searchParams.has(param)) {
				url.searchParams.delete(param);
			}
		});

		window.history.pushState({}, '', url.toString());

		set({
			isMaximized: false,
			content: null,
			maximizedKey: undefined,
			config: null,
			exitConfirmed: false,
		});

		// Trigger all callbacks in order
		get().unmaximizeCallbacks.forEach((callback) => callback());
	},

	addUnmaximizeCallback: (callback) => {
		set((state) => {
			if (!state.unmaximizeCallbacks.includes(callback)) {
				return { unmaximizeCallbacks: [...state.unmaximizeCallbacks, callback] };
			}
			return state;
		});
	},

	removeUnmaximizeCallback: (callback) => {
		set((state) => ({
			unmaximizeCallbacks: state.unmaximizeCallbacks.filter((cb) => cb !== callback),
		}));
	},

	trackParam: (param) => {
		set((state) => {
			const updatedParams = new Set(state.trackedParams);
			updatedParams.add(param); // Add only if not already present
			return { trackedParams: updatedParams };
		});
	},

	removeTrackedParam: (param) => {
		set((state) => {
			const updatedParams = new Set(state.trackedParams);
			updatedParams.delete(param); // Remove if present
			return { trackedParams: updatedParams };
		});
	},
}));
