import { create } from 'zustand';
import { asyncWrap, getUserSettings, updateUserSettings } from '@/api';
import { userUtils, utils } from '@/helpers';
import { deleteUserSettings } from '../api/user-settings';
import { UserSettings } from '@alpine-iq/shared';
import { EntryMap } from '@/legacy-types';

type Data = EntryMap<Partial<UserSettings>>;
export type UserSettingsState = {
	loading: boolean;
	loaded: boolean;
	userSettingsMap: Data;
	hasLoaded: (storeId?: string) => boolean;
	getUserSettings: (storeId?: string, noFetch?: boolean) => Promise<Partial<UserSettings>>;
	setUserSettings: (settings: Partial<UserSettings>, storeId?: string) => Partial<UserSettings>;
	putUserSettings: (settings: Partial<UserSettings>, storeId?: string) => Promise<Partial<UserSettings>>;
	deleteUserSettings: (storeId?: string) => Promise<void>;
	fetchUserSettings: (storeId?: string, noCache?: boolean) => Promise<Partial<UserSettings>>;
};

// Cache duration in milliseconds (1 minute)
const CACHE_DURATION = 60 * 1000;
const CACHE_KEY = 'userSettings';

export const useUserSettingsStore = create<UserSettingsState>((set, get) => ({
	loaded: false,
	loading: true,
	userSettingsMap: {},

	hasLoaded(storeId = '-1') {
		const { userSettingsMap = {} } = get();
		return !!userSettingsMap[storeId];
	},

	async getUserSettings(storeId = '-1', noFetch?: boolean) {
		const { userSettingsMap = {} } = get();
		// If settinsg key doesn't exist, then fetch from the global settings
		const settingsObject = userSettingsMap[storeId];

		if (!settingsObject && !noFetch) {
			const settings = await this.fetchUserSettings(storeId);
			return settings;
		}

		return settingsObject ?? {};
	},

	// Set user settings on the userSettingsMap
	setUserSettings(userSettings: Partial<UserSettings>, storeId = '-1') {
		const { userSettingsMap = {} } = get();
		userSettingsMap[storeId] = userSettings;
		cacheData(userSettingsMap, storeId);
		set({ userSettingsMap });
		return userSettings;
	},

	putUserSettings(newSettings: Partial<UserSettings>, storeId = '-1') {
		return asyncWrap(
			async () => {
				set({ loading: true });
				// Add validation here
				const userSettings = (await updateUserSettings(newSettings, StoreID(storeId))) ?? newSettings;
				const { userSettingsMap = {} } = get();
				userSettingsMap[storeId] = userSettings;
				set({ userSettingsMap, loading: false });
				cacheData(userSettingsMap, storeId);
				return userSettings;
			},
			{
				errorCallback(error) {
					set({ loading: false });
					throw error;
				},
			},
		) as Promise<Partial<UserSettings>>;
	},

	deleteUserSettings(storeId = '') {
		return asyncWrap(
			async () => {
				set({ loading: true });
				await deleteUserSettings(StoreID(storeId));
				const { userSettingsMap = {} } = get();
				delete userSettingsMap[storeId];
				set({ userSettingsMap, loading: false });
				cacheData(userSettingsMap, storeId);
			},
			{
				errorCallback(error) {
					set({ loading: false });
					throw error;
				},
			},
		);
	},

	fetchUserSettings: async (storeId = '', merge = false, noCache?: boolean) => {
		const userSettingsMap = getCachedData(storeId);
		if (userSettingsMap && !noCache) {
			set({
				userSettingsMap,
				loaded: true,
			});
			if (userUtils.debugMode()) {
				console.log('User settings fetched from cache');
			}
		}
		return (await asyncWrap(
			async () => {
				set({ loading: true });
				const userSettings = (await getUserSettings(StoreID(storeId), { queryParams: { merge } })) ?? {};
				const { userSettingsMap = {} } = get();
				userSettingsMap[storeId] = userSettings;
				set({ userSettingsMap, loaded: true, loading: false });
				cacheData(userSettingsMap, storeId);
				return userSettings;
			},
			{
				errorCallback(error) {
					set({ loading: false });
					throw error;
				},
			},
		)) as Promise<Partial<UserSettings>>;
	},
}));

const StoreID = (storeId: string | number): string => {
	if (`${storeId}` === '-1') return '';
	return `${storeId}`;
};

type UserSettingsCache = {
	inserted: number;
	data: Data;
};

const cacheData = (data: Data, storeId = '-1') => {
	const cache: UserSettingsCache = {
		inserted: Date.now(),
		data,
	};

	const cacheKey = `${utils.uid ?? '-1'}:::${CACHE_KEY}:::${storeId}`;
	localStorage.setItem(cacheKey, JSON.stringify(cache));
};

const getCachedData = (storeId = ''): Data | null => {
	const cacheKey = `${utils.uid ?? '-1'}:::${CACHE_KEY}:::${storeId}`;
	const cache = localStorage.getItem(cacheKey);
	if (!cache) return null;

	// Check if cache is valid (less than CACHE_DURATION)
	const parsedCache: UserSettingsCache = JSON.parse(cache);
	if (Date.now() - parsedCache.inserted > CACHE_DURATION) {
		return null;
	}

	return parsedCache.data;
};

export default useUserSettingsStore;
