import { create } from 'zustand';

type KeyValueStoreState = {
	isStaffAppEnabled?: boolean;
};

type KeyValueStoreActions = {
	setValue: (key: keyof KeyValueStoreState, value: KeyValueStoreState[keyof KeyValueStoreState]) => void;
	setValues: (value: KeyValueStoreState) => void;
	reset: () => void;
};

export const useKeyValueStore = create<KeyValueStoreState & KeyValueStoreActions>((set, get) => ({
	isStaffAppEnabled: false,
	setValue: (key, value) => set({ [key]: value }),
	setValues: (value) => set(value),
	reset: () => set({}),
}));
